<template>
  <div class="regist-container">
    <div class="regist-body">
      <el-card
        class=""
        :body-style="{ 'padding': '20px 30px' }"
      >
        <regist-view></regist-view>
      </el-card>
    </div>
  </div>
</template>

<script>
import RegistView from './components/regist'

export default {
  components: {
    RegistView,
  },
}
</script>

<style lang="scss" scoped>
.regist-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url('~@/assets/images/login-bg.jpg') center / cover;

  .regist-body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    transform: translate(-50%, -50%);
  }
}
</style>
