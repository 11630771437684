<template>
  <div class="user-password">
    <el-form
      ref="loginForm"
      :model="formData"
      :rules="formRules"
    >
      <el-form-item prop="username">
        <el-input
          v-model="formData.username"
          placeholder="输入手机号"
          @keyup.enter.native="onLogin"
          prefix-icon="el-icon-mobile"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="formData.password"
          type="password"
          placeholder="输入密码"
          prefix-icon="el-icon-lock"
          @keyup.enter.native="onLogin"
        >
        </el-input>
      </el-form-item>
      <el-form-item style="margin-top: -15px;">
        <div class="regist-box">
          <el-link
            class="g-mr-10"
            :underline="false"
            @click="$router.push({ name: 'password' })"
          >忘记密码</el-link>
          <el-link
            :underline="false"
            @click="$router.push({ name: 'regist' })"
          >立即注册</el-link>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          block
          :loading="loading"
          @click.native.prevent="onLogin"
        >登录</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>

<script>
import { userLogin } from '@/api/admin'

export default {
  data() {
    return {
      loading: false,
      title: '农牧厅精准监督',
      formData: {
        // username: '18586084935',
        username: '',
        password: '',
      },
      formRules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    }
  },
  methods: {
    fetchLogin() {
      this.loading = true
      const params = {
        phone: this.formData.username,
        password: this.formData.password,
      }
      userLogin(params)
        .then(res => {
          return this.$store.dispatch('user/USER_LOGIN', res)
        })
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.loading = false
        })
    },
    onLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.fetchLogin()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-password {
  .user-icon,
  .password-icon,
  .valid-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
  }

  // .user-icon {
  //   background: url('~@/assets/icons/user.png') 100% / cover;
  // }

  // .password-icon {
  //   background: url('~@/assets/icons/password.png') 100% / cover;
  // }

  // .valid-icon {
  //   background: url('~@/assets/icons/valid.png') 100% / cover;
  // }

  /deep/ {
    .el-input__inner {
      border: none;
      border-bottom: 1px solid #dcdfe6;
      border-radius: 0;
    }
  }

  .regist-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
  }
}
</style>
